import { BrokerIssuerBanner } from "@sgi/gravity/BrokerIssuerBanner";
import { FunctionComponent } from "react";
import { useBanner } from "../Hooks/useBanner";

const Banner: FunctionComponent = () => {
  const { issuerId, brokerId } = useBanner();
  return (
    <BrokerIssuerBanner
      containerAs="div"
      issuerNumber={issuerId ?? undefined}
      brokerNumber={brokerId ?? undefined}
    />
  );
};

export default Banner;
